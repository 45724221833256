<!-- eslint-disable camelcase -->
<template>
  <div>
    <div v-if="notFound">
      <b-card class="text-center">
        <feather-icon
          size="80"
          icon="SearchIcon"
        />
        <div class="error mt-2">
          <h2 class="my-2">
            Campaign is not found
          </h2>
          <b-button
            variant="outline-primary"
            @click="() => $router.push({name: 'watch-campaigns'})"
          >
            Back to Campaigns
          </b-button>
        </div>
      </b-card>
    </div>
    <div v-else>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <div class="contact-box center-version">
              <div class="d-flex align-items-center flex-column">

                <div>
                  <video
                    controls
                    :src="viewData.video.name"
                    width="400"
                  /></div>

              </div>
              <div class="product_gifts-info">
                <ul>

                  <li>
                    <feather-icon
                      icon="GiftIcon"
                    /> Gift: <span>

                      {{ viewData.gift_name }}

                    </span>
                  </li>
                  <li v-if="viewData.limited==1">
                    <feather-icon
                      icon="ShoppingCartIcon"
                    /> Quantity : {{ viewData.quantity_limit }}
                  </li>
                  <li v-if="viewData.limited==0">
                    <feather-icon
                      icon="ShoppingCartIcon"
                    /> Quantity : Unlimited
                  </li>
                  <li>
                    <feather-icon
                      icon="AwardIcon"
                    /> Ticket Count: {{ viewData.ticket_count }}
                  </li>

                  <li v-if="viewData.coming_soon_date">
                    <feather-icon
                      icon="CalendarIcon"
                    />Coming Soon Date: {{ viewData.coming_soon_date | formatDate }}
                  </li>
                  <li v-if="viewData.draw_date">
                    <feather-icon
                      icon="CalendarIcon"
                    />Draw Date: {{ viewData.draw_date | formatDate }}
                  </li>

                  <li>
                    <feather-icon
                      icon="BellIcon"
                    />Alert Before Days: {{ viewData.alert_before_days }}
                  </li>
                </ul>
              </div>
            </div>
          </b-card>
          <b-card>
            <h4 class="mb-2">
              <h3 class="mb-2">
                Winners
              </h3>
              <div
                v-if="viewData.winners.length>0"
              >
                <div
                  v-for="winner in viewData.winners"
                  :key="winner.id"
                  class="d-flex conOfWinner"
                ><b-col cols="5">
                   <router-link :to="{ name: 'show-user-customer', params: { id: winner.user_id } }">
                     {{ winner.user_name }}
                   </router-link>
                 </b-col>    <b-col cols="1"> <feather-icon
                   icon="GiftIcon"
                 /></b-col>
                  <b-col cols="5">
                    {{ winner.gift_name }}
                  </b-col></div>

              </div>
              <div
                v-else
                class="text-center"
              >
                <strong>Not Drawn Yet </strong>
              </div>

            </h4>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="8"
        > <b-card>
          <div>
            <div class="d-flex justify-content-between align-items-center my-2">
              <h3 class="">
                Tickets
              </h3>
              <b-button
                v-if="remaningGifts.length!==0"
                variant="primary"
                @click="openDrawDialog()"
              >
                <span class="text-nowrap">Random Draw </span>
              </b-button>
            </div>

            <b-table
              ref="TicketsTable"
              :items="TicketsData"
              :fields="columns"
              aria-busy="true"
              class="position-relative"
              responsive
              show-empty
              bordered
              primary-key="id"
            >
              <template
                #cell(gift_names)="data"
              >
                <div class="giftColumn">
                  <ul
                    v-for="(gift,index) in data.item.gift_names"
                    :key="index"
                    class="pl-1"
                  >
                    <li>
                      {{ gift.name }}

                    </li>
                  </ul>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="end"
              first-number
              last-number
              :total-visible="7"
              :length="Math.ceil(totalRows / 10)"
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @click.native="getdata()"
            >

              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card>
        </b-col>
        <div>
          <b-modal
            id="randomDraw"
            ref="randomDraw"
            hide-footer
            centered
            title="Random Draw"
          >
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-form-radio-group
                v-model="selectedGiftId"
                :options="remaningGifts"
                class="mb-5"
                value-field="id"
                text-field="name"
                stacked
              />

            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-button
                v-if="!loaderDraw"
                variant="primary"
                class="mr-1"
                @click.prevent="randomDraw()"
              >
                Draw
              </b-button>
              <b-button
                variant="outline-danger"
                class="closeModal"
                @click="closeDrawModal()"
              >
                Cancel
              </b-button>
              <b-button
                v-if="loaderDraw"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-modal>
        </div>
        <!-- campaignWinner Modal -->
        <div>
          <b-modal
            id="campaignWinner"
            ref="campaign-winner-modal"
            hide-footer
            centered
            title="Campaign's Winner"
          >
            <b-row>
              <b-col>
                <img
                  v-img
                  src="@/assets/images/other/couponWinnwerGif.gif"
                  class="winnerGif"
                >
              </b-col>
              <b-col
                cols="12"
                class=" d-flex justify-content-center"
              >
                <img
                  v-img
                  :src="viewData.image"
                  width="200"
                >
              </b-col>
              <b-col
                cols="12"
                class="text-center my-2"
              >
                <h2><span>Winner:</span>{{ winnerData.name }} </h2>   </b-col>

              <b-col
                cols="12"
                md="12"
                class="text-center"
              >
                <b-button
                  v-if="!loader"
                  variant="primary"
                  class="mr-1"
                  :disabled="disableUpdateStatusBtn"
                  @click="getdata"
                >
                  Ok
                </b-button>

              </b-col>
            </b-row>
          </b-modal>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  data() {
    return {
      totalRows: 0,
      perPage: 10,
      loaderDraw: false,
      selectedGiftId: null,
      remaningGifts: [],
    }
  },
  setup() {
    const viewData = ref({})
    // eslint-disable-next-line camelcase
    const product_giftsForm = ref({})
    const winnerData = ref({})

    const { formData, setFormData } = handleFormData()
    const columns = [
      { key: 'code', label: 'Code' },
      { key: 'user_name', label: 'User' },
      { key: 'gift_name', label: 'Gift' },
      { key: 'voters_count', label: 'Voters' },
      { key: 'is_free', label: 'Free' },
      { key: 'is_draw', label: 'Draw' },
    ]
    const currentPage = ref(1)
    // eslint-disable-next-line camelcase
    const product_giftsAddresses = ref([])
    // eslint-disable-next-line camelcase
    const product_giftsFiles = ref([])
    const loader = ref(false)
    const notFound = ref(false)
    // eslint-disable-next-line camelcase
    const selected_active_type = ref('')

    const options = [
      { value: 'national_id', name: 'National ID' },
    ]
    const TicketsData = ''
    const activeType = [
      { text: 'Accepted', value: 'Accepted' },
      { text: 'Rejected', value: 'Rejected' },
      { text: 'Pending', value: 'Pending' },
      { text: 'Hold', value: 'Hold' },
    ]

    return {
      winnerData,
      viewData,
      columns,
      currentPage,
      TicketsData,
      product_giftsAddresses,
      product_giftsFiles,
      options,
      loader,
      avatarText,
      notFound,
      activeType,
      product_giftsForm,
      selected_active_type,
      types: {},
      formData,
      setFormData,
    }
  },
  computed: {
    disableUpdateStatusBtn() {
      return this.selected_active_type === 'Rejected' && !this.product_giftsForm.rejection_reason
    },
  },
  created() {
    this.getdata()
  },
  methods: {
    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },
    closeDrawModal() {
      this.$refs.randomDraw.hide()
    },

    openDrawDialog() {
      this.$refs.randomDraw.show()
    },
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Active') return 'success'
      return 'danger'
    },
    getdata() {
      let skip
      if (this.currentPage !== 1) {
        skip = this.currentPage * 10 - 10
      } else {
        skip = 0
      }
      this.loader = true
      this.$bvModal.hide('campaignWinner')

      axios.get(`admin/product_gifts/${this.$route.params.id}?skip=${skip}`).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.viewData = res.data?.data.product_gift
          this.remaningGifts = res.data?.data.product_gift.remaining_gifts
          this.TicketsData = res.data?.data.tickets
          this.totalRows = res.data.data.count
        }
      }).catch(error => {
        if ((error.response.status === 400) || error.response.status === 404) {
          this.notFound = true
        }
      }).finally(() => {
        this.loader = false
      })
    },
    changeproduct_giftsType() {
      this.loader = true
      this.product_giftsForm.active_type = this.selected_active_type
      this.setFormData(this.product_giftsForm)
      this.formData.append('_method', 'PUT')
      axios.post(`product_gifts/${this.product_giftsForm.id}`, this.formData).then(res => {
        if (res.status === 200) {
          this.$bvModal.hide('campaignWinner')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
    randomDraw() {
      this.$swal({
        title: 'Draw',
        text: 'Are you sure you want to make draw for this campaign?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Draw!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.get(`admin/random_draw_watch/${this.$route.params.id}/${this.selectedGiftId}`).then(res => {
            if (res.status === 200) {
              this.winnerData = res.data?.data.winner

              this.$bvModal.show('campaignWinner')
            }
          }).catch(error => {
            if (error.response.status === 500) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ooops!!! An Unexpected Internal server error',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            } else if (error.response.status === 422) {
              this.errorMsg = error.response.data.errors
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.errors,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }
          }).finally(() => {
            this.loaderDraw = false
          })
        }
      })
    },

  },

}
</script>

<style lang="scss">
.custom-control.custom-radio {
    margin: 5px;
}
.giftBadge{
  margin: 0px 5px;
}
.giftColumn{
  min-width: 85px;
}
.conOfWinner{
  margin-bottom: 5px;
}
.winnerGif{
  width: 90%;
  height: 320px;
  position: absolute;

}
/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a{
    text-decoration:none !important;
}

.product_gifts-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
.change-status {
  cursor: pointer;
}
</style>
